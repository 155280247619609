// http client service

// imports
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

// axios instance
export const httpClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// interceptors
httpClient.interceptors.request.use((config: AxiosRequestConfig) => {
  // check if headers exist
  if (config.headers) {
    // set headers
    config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  }
  return config;
});
