import {
  Avatar,
  Button,
  Divider,
  message,
  PageHeader,
  Popconfirm,
  Table,
} from "antd";
import type { ColumnsType } from "antd/lib/table";
import * as React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableBodyRow from "../components/row.component";
import { ICamino, ICaminoCategory } from "../caminos.interfaces";
import { CaminosService } from "../caminos.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CaminosContext } from "../caminos.context";
import EditCaminoModal from "../components/edit-modal.component";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

const caminosService = new CaminosService();

const CaminosPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = React.useState<ICamino[]>([]);
  const [category, setCategory] = React.useState<ICaminoCategory | undefined>(
    undefined
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  const { setCamino } = React.useContext(CaminosContext);

  const [orderChanged, setOrderChanged] = React.useState<boolean>(false);

  const [editModalVisible, setEditModalVisible] =
    React.useState<boolean>(false);

  const columns: ColumnsType<ICamino> = [
    {
      title: "Order",
      key: "order",
      render: (text, record, index) => index + 1,
      width: 50,
    },
    {
      title: "Icon",
      key: "icon",
      render: (record) => <Avatar src={record.icon} />,
      width: 50,
      align: "center",
    },
    {
      title: "Cover",
      key: "cover",
      align: "center",
      render: (record) => (
        <img
          alt=""
          src={record.cover}
          style={{
            width: 64,
            height: 32,
            borderRadius: 2,
            border: "none",
            objectFit: "cover",
            backgroundColor: "#ccc",
          }}
        />
      ),
      width: 64,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/caminos/${id}/${record._id}`}>{text}</Link>
      ),
    },
    {
      title: "Public",
      dataIndex: "public",
      key: "public",
      align: "center",
      render: (text, record) =>
        text ? (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ) : (
          <CloseCircleTwoTone twoToneColor="#eb2f96" />
        ),
    },
    {
      title: "Actions",
      key: "actions",
      align: "right",
      render: (camino: ICamino) => (
        <div>
          <Button
            style={{ padding: 0 }}
            type="link"
            onClick={() => {
              setCamino(camino);
              setEditModalVisible(true);
            }}
          >
            Edit
          </Button>
          <Divider type="vertical" />
          <Button
            style={{ padding: 0 }}
            type="link"
            onClick={() => handleCloneCamino(camino._id)}
          >
            Clone
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure you want to delete this camino?"
            onConfirm={() => handleDeleteCamino(camino._id)}
            onCancel={() => null}
            okText="Yes"
            cancelText="No"
            placement="topRight"
          >
            <Button style={{ padding: 0 }} type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = data[dragIndex];
      // place the row at the new location
      const newData = [...data];
      newData.splice(dragIndex, 1);
      newData.splice(hoverIndex, 0, dragRow);
      setData(newData);
      setOrderChanged(true);
    },
    [data]
  );

  // get data
  React.useEffect(() => {
    setLoading(true);

    caminosService
      .getJourneysByCategory(id as string, {})
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to get caminos");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  // get category
  React.useEffect(() => {
    caminosService.getOneCategory(id as string).then((res) => {
      setCategory(res.data);
    });
  }, [id]);

  // handle delete camino
  const handleDeleteCamino = (id: string) => {
    caminosService
      .delete(id)
      .then(() => {
        const index = data.findIndex((c) => c._id === id);
        if (index >= 0) {
          data.splice(index, 1);
          setData([...data]);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to delete camino");
        }
      });
  };

  // handle create camino
  const handleCreateCamino = () => {
    caminosService
      .create(id as string)
      .then((res) => {
        setData([res.data, ...data]);
        navigate(`/caminos/${id}/${res.data._id}`);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to create camino");
        }
      });
  };

  // handle clone camino
  const handleCloneCamino = (id: string) => {
    caminosService
      .cloneJourney(id)
      .then((res) => {
        // find all that have same order
        // place the new camino at the end of those
        const order: number = res.data.order;
        // find last with same order
        const lastIndex = data.map((c) => c.order).lastIndexOf(order);
        // place the new camino after last index
        const newData = [...data];
        newData.splice(lastIndex + 1, 0, res.data);
        setData(newData);
        navigate(`/caminos/${id}/${res.data._id}`);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to clone camino");
        }
      });
  };

  // callback for edit modal
  const handleUpdateCamino = (camino: ICamino) => {
    // if journey_category is changed, navigate to new category
    if (camino.journey_category !== id) {
      navigate(`/caminos/${camino.journey_category}`);
    } else {
      // find camino in data and update it
      const index = data.findIndex((c) => c._id === camino?._id);

      if (index >= 0) {
        data[index] = camino;
        setData([...data]);
      }
    }
  };

  // handle update order
  const handleUpdateOrder = () => {
    // order is array of camino ids
    const order: string[] = data.map((c) => c._id);

    caminosService
      .updateCaminoOrder({ order })
      .then(() => {
        message.success("Order updated");
        setOrderChanged(false);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to update order");
        }
      });
  };

  return (
    <div>
      <PageHeader
        title="Caminos"
        subTitle={category?.name}
        onBack={() => navigate(`/caminos/`)}
        extra={[
          <Button
            key="order"
            type="primary"
            onClick={() => handleUpdateOrder()}
            disabled={!orderChanged}
          >
            Save order
          </Button>,
          <Button key="add" type="primary" onClick={handleCreateCamino}>
            Add Camino to Category
          </Button>,
        ]}
      />
      <DndProvider backend={HTML5Backend}>
        <Table
          loading={loading}
          pagination={false}
          style={{
            margin: 24,
          }}
          columns={columns}
          dataSource={data}
          components={components}
          onRow={(_, index) => {
            const attr = {
              index,
              moveRow,
            };
            return attr as React.HTMLAttributes<any>;
          }}
        />
      </DndProvider>

      <EditCaminoModal
        visible={editModalVisible}
        setVisible={setEditModalVisible}
        callback={handleUpdateCamino}
      />
    </div>
  );
};

export default CaminosPage;
