// caminos context

// imports
import { message } from "antd";
import * as React from "react";
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "react-flow-renderer";
import { useNavigate } from "react-router-dom";
import {
  ICamino,
  ICaminoMessage,
  IEdge,
  MessageType,
  ResponseType,
} from "./caminos.interfaces";
import { CaminosService } from "./caminos.service";

const DEFAULT_MESSAGE: Partial<ICaminoMessage> = {
  title: "Untitled message",
  message_type: MessageType.TEXT,
  response_type: ResponseType.TEXT,
  delay: 1,
};

const caminosService = new CaminosService();

type Props = {
  children: React.ReactNode;
};

type ContextTypes = {
  camino: Partial<ICamino> | undefined;
  setCamino: (camino: Partial<ICamino>) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  // methods
  getCamino: (id: string) => void;
  deleteCamino: (id: string) => void;
  addMessage: () => void;
  deleteMessage: (id: string) => void;
  updateMessage: (id: string, message: Partial<ICaminoMessage>) => void;
  saveCamino: () => void;
  // nodes and edges
  nodes: Node[];
  edges: Edge[];
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  // parsers
  parseNodesToMessages: () => Partial<ICaminoMessage>[];
  parseEdgesToCaminoEdges: () => Partial<IEdge>[];
};

// context
export const CaminosContext = React.createContext<ContextTypes>({
  camino: undefined,
  setCamino: () => {},
  isLoading: false,
  setIsLoading: () => {},
  // methods
  getCamino: () => {},
  deleteCamino: () => {},
  addMessage: () => {},
  deleteMessage: () => {},
  updateMessage: () => {},
  saveCamino: () => {},
  // nodes and edges
  nodes: [],
  edges: [],
  onNodesChange: () => {},
  onEdgesChange: () => {},
  onConnect: () => {},
  // parsers
  parseNodesToMessages: () => [],
  parseEdgesToCaminoEdges: () => [],
});

// provider
export const CaminosProvider: React.FC<Props> = ({ children }) => {
  const [camino, setCamino] = React.useState<Partial<ICamino> | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  const reactFlowInstance = useReactFlow();

  const navigate = useNavigate();

  // get camino
  const getCamino = (id: string) => {
    setIsLoading(true);

    caminosService
      .getOne(id)
      .then((response) => {
        setCamino(response.data);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to get camino");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // delete camino
  const deleteCamino = (id: string) => {
    setIsLoading(true);

    caminosService
      .delete(id)
      .then(() => {
        message.success("Camino deleted successfully");
        navigate(`/caminos/${camino?.journey_category}`);
        setCamino(undefined);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to delete camino");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // add message
  const addMessage = () => {
    // get current viewport
    const viewport = reactFlowInstance.getViewport();

    // multiply by zoom
    let x = viewport.x / viewport.zoom;
    let y = viewport.y / viewport.zoom;

    // parse
    x = parseInt(x.toString(), 10);
    y = parseInt(y.toString(), 10);

    // set x and y to be closest number divisible by 20
    x = Math.round(x / 20) * 20 - 20;
    y = Math.round(y / 20) * 20 - 20;

    // make them negative
    x = -x;
    y = -y;

    // find if there is a node at this position
    const findNodeAtPosition = (x: number, y: number) => {
      return nodes.find((node) => {
        return node.position.x === x && node.position.y === y;
      });
    };

    // offset
    const offset = 20;

    // add 20 to offset as long as there is a node at this position
    while (findNodeAtPosition(x, y)) {
      x += offset;
      y += offset;
    }

    // new message
    const newMessage: Partial<ICaminoMessage> = {
      ...DEFAULT_MESSAGE,
      position: { x: x, y: y },
    };

    // parse nodes to messages
    const newMessages: Partial<ICaminoMessage>[] = parseNodesToMessages();

    // parse edges to camino edges
    const newEdges: Partial<IEdge>[] = parseEdgesToCaminoEdges();

    // add new message
    newMessages.push(newMessage);

    updateCamino(newMessages, newEdges);
  };

  // delete message
  const deleteMessage = (id: string) => {
    if (!camino || !camino.messages || !camino.edges) return;

    // remove edges where source or target is the message._id
    const newEdges: Partial<IEdge>[] = camino?.edges?.filter(
      (edge) => edge.source !== id && edge.target !== id
    );

    let newMessages: Partial<ICaminoMessage>[] = parseNodesToMessages();

    // remove message
    newMessages = newMessages.filter((message) => message._id !== id);

    // set camino
    setCamino({
      ...camino,
      messages: newMessages,
      edges: newEdges,
    });
  };

  // update message
  const updateMessage = (id: string, message: Partial<ICaminoMessage>) => {
    if (!camino || !camino.messages) return;

    // parse nodes to messages
    let newMessages: Partial<ICaminoMessage>[] = parseNodesToMessages();

    // find message and update
    newMessages = newMessages.map((m) => {
      if (m._id === id) {
        return {
          ...m,
          ...message,
          position: m.position,
        };
      }

      return m;
    });

    // parse edges to camino edges
    const newEdges: Partial<IEdge>[] = parseEdgesToCaminoEdges();

    setCamino({
      ...camino,
      messages: newMessages,
      edges: newEdges,
    });
  };

  // update camino
  const updateCamino = (
    newMessages?: Partial<ICaminoMessage>[],
    newEdges?: Partial<IEdge>[]
  ) => {
    if (!camino || !camino._id) return;

    setIsLoading(true);

    caminosService
      .update(camino._id, {
        messages: newMessages || camino.messages,
        edges: newEdges || camino.edges,
      })
      .then((response) => {
        message.success("Camino updated successfully");
        setCamino(response.data);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to save camino");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // save camino
  const saveCamino = () => {
    if (!camino || !camino._id) return;

    setIsLoading(true);

    // parse nodes to messages
    const newMessages: Partial<ICaminoMessage>[] = parseNodesToMessages();

    // parse edges to camino edges
    const newEdges: Partial<IEdge>[] = parseEdgesToCaminoEdges();

    caminosService
      .update(camino._id, {
        messages: newMessages,
        edges: newEdges,
      })
      .then((response) => {
        message.success("Camino saved successfully");
        setCamino(response.data);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to save camino");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // parse messages to nodes
  const parseMessagesToNodes = (): Node[] => {
    if (!camino || !camino.messages) return [];

    return camino.messages.map((message, index: number) => ({
      id: message._id as string,
      type: "message",
      data: message,
      position: message.position || { x: 0, y: index * 400 + 50 },
      dragHandle: ".custom-drag-handle",
    }));
  };

  // parse nodes to messages
  const parseNodesToMessages = (): Partial<ICaminoMessage>[] => {
    return nodes.map((node) => {
      return {
        ...camino?.messages?.find((message) => message._id === node.id),
        position: node.position,
      };
    });
  };

  // parse camino edges to edges
  const parseCaminoEdgesToEdges = (): Edge[] => {
    if (!camino || !camino.edges) return [];

    return camino?.edges?.map((edge) => ({
      id: edge._id as string,
      source: edge.source as string,
      target: edge.target as string,
      sourceHandle: edge.source_handle as string,
      targetHandle: edge.target_handle as string,
      style: {
        stroke: "#1890ff",
        strokeWidth: 4,
      },
    }));
  };

  // parse edges to camino edges
  const parseEdgesToCaminoEdges = (): Partial<IEdge>[] => {
    return edges.map((edge) => {
      return {
        // assign only non-empty values
        source: edge.source,
        target: edge.target,
        source_handle: edge.sourceHandle || undefined,
        target_handle: edge.targetHandle || undefined,
      };
    });
  };

  const onNodesChange = React.useCallback(
    (changes: NodeChange[]) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  const onEdgesChange = React.useCallback(
    (changes: EdgeChange[]) => {
      setEdges((eds) =>
        applyEdgeChanges(
          changes,
          eds.map((edge) => ({
            ...edge,
            style: {
              // change stroke color if edge is selected
              stroke: edge.selected ? "#FF4D4F" : "#1890ff",
              strokeWidth: 4,
            },
          }))
        )
      );
    },
    [setEdges]
  );

  const onConnect = React.useCallback(
    (connection: Connection) => {
      setEdges((eds) =>
        addEdge(
          {
            ...connection,
            style: {
              stroke: "#1890ff",
              strokeWidth: 4,
            },
          },
          eds
        )
      );
    },
    [setEdges]
  );

  // on camino change parse messages to nodes and camino edges to edges
  React.useEffect(() => {
    const newNodes: Node[] = parseMessagesToNodes();
    const newEdges: Edge[] = parseCaminoEdgesToEdges();

    setNodes(newNodes);
    setEdges(newEdges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camino]);

  const value = {
    camino,
    setCamino,
    isLoading,
    setIsLoading,
    // methods
    getCamino,
    deleteCamino,
    addMessage,
    deleteMessage,
    updateMessage,
    saveCamino,
    // nodes and edges
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    // parsers
    parseNodesToMessages,
    parseEdgesToCaminoEdges,
  };

  return (
    <CaminosContext.Provider value={value}>{children}</CaminosContext.Provider>
  );
};
