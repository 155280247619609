// journey message component

// imports
import * as React from "react";
import {
  Form,
  Card,
  Input,
  Checkbox,
  Typography,
  Radio,
  Button,
  Row,
  Col,
  Popconfirm,
  Select,
  InputNumber,
  Popover,
} from "antd";
import {
  ICaminoMessage,
  MessageType,
  ResponseType,
} from "../caminos.interfaces";
import {
  DeleteOutlined,
  DragOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { CaminosContext } from "../caminos.context";
import { useUpdateNodeInternals } from "react-flow-renderer";
import PopoverContent from "./popover-content.component";

type Props = {
  message?: Partial<ICaminoMessage>;
};

let editingTimeout: any;
let doneEditingInterval: number = 500;

const MessageForm: React.FC<Props> = ({ message }) => {
  const { updateMessage, deleteMessage } = React.useContext(CaminosContext);
  const [localMessage, setLocalMessage] = React.useState<
    Partial<ICaminoMessage> | undefined
  >(message);

  const [form] = Form.useForm();

  const [responseType, setResponseType] = React.useState<ResponseType>(
    message?.response_type || ResponseType.TEXT
  );
  const [messageType, setMessageType] = React.useState<MessageType>(
    message?.message_type || MessageType.TEXT
  );
  const [isUseAsCategory, setIsUseAsCategory] = React.useState<boolean>(
    message?.additional_options?.use_as_category || false
  );

  const updateNodeInternals = useUpdateNodeInternals();

  // update form values when the message changes
  React.useEffect(() => {
    if (!message) return;

    form.setFieldsValue({
      ...message,
      response_type: message.response_type || ResponseType.TEXT,
    });

    updateNodeInternals(message._id as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, form]);

  React.useEffect(() => {
    // clear timeout if it has already been set
    clearTimeout(editingTimeout);

    // start timer again
    editingTimeout = setTimeout(() => {
      // handleUpdate();
      if (localMessage) {
        updateMessage(localMessage?._id as string, localMessage);
      }
    }, doneEditingInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localMessage]);

  return (
    <Card
      hoverable
      style={{
        width: 320,
        cursor: "default",
      }}
    >
      <div className="custom-drag-handle">
        <DragOutlined style={{ color: "#4D38A4" }} />
      </div>

      <div style={{ height: 32 }} />

      <Form
        form={form}
        size="small"
        colon={false}
        onFieldsChange={(changedFields, allFields) => {
          const responseTypeChange = changedFields.find(
            (field) => field.name.toString() === "response_type"
          );

          if (responseTypeChange) {
            setResponseType(responseTypeChange.value);
            return;
          }

          const messageTypeChange = changedFields.find(
            (field) => field.name.toString() === "message_type"
          );

          if (messageTypeChange) {
            setMessageType(messageTypeChange.value);
            return;
          }

          const isUseAsCategoryChange = changedFields.find(
            (field) =>
              field.name.toString() === "additional_options,use_as_category"
          );

          if (isUseAsCategoryChange) {
            setIsUseAsCategory(isUseAsCategoryChange.value);
            return;
          }
        }}
        initialValues={{
          ...localMessage,
        }}
        onValuesChange={(changedValues, allValues) => {
          setLocalMessage({
            ...localMessage,
            ...allValues,
          });
        }}
      >
        <Row justify="space-between">
          <Form.Item name="title" label="Title">
            <Input placeholder="Enter message title" />
          </Form.Item>
          <Popconfirm
            title="Are you sure you want to delete this message?"
            onConfirm={() => deleteMessage(message?._id as string)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger size="small" icon={<DeleteOutlined />}>
              {/* Delete */}
            </Button>
          </Popconfirm>
        </Row>
        <Form.Item>
          ID: <Typography.Text copyable>{message?._id}</Typography.Text>
        </Form.Item>

        {/* is first */}
        <Form.Item
          name="is_first"
          label="First message"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        {/* message type */}
        <Form.Item name="message_type" label="Message type">
          <Select
            placeholder="Select message type"
            options={[
              { label: "Text", value: MessageType.TEXT },
              { label: "Image", value: MessageType.IMAGE },
              { label: "Audio", value: MessageType.AUDIO },
            ]}
          />
        </Form.Item>

        {/* file as text */}
        {messageType !== MessageType.TEXT && (
          <Popover
            content={<PopoverContent url={message?.message_file as string} />}
          >
            <Form.Item
              labelCol={{
                span: 24,
              }}
              label="File"
              name="message_file"
            >
              <Input placeholder="Enter file URL" />
            </Form.Item>
          </Popover>
        )}

        {/* message text */}
        <Form.Item
          label="Message text"
          name="message_text"
          labelCol={{
            span: 24,
          }}
        >
          <Input.TextArea
            placeholder="Enter message text"
            autoSize={{ minRows: 3, maxRows: 6 }}
          />
        </Form.Item>

        {/* delay */}
        <Form.Item name="delay" label="Delay">
          <InputNumber
            min={1}
            max={10}
            step={1}
            placeholder="Enter delay in seconds"
            addonAfter="seconds"
          />
        </Form.Item>

        {/* response type */}
        {!isUseAsCategory && (
          <Form.Item
            label="Response type"
            name="response_type"
            labelCol={{ span: 24 }}
          >
            <Radio.Group buttonStyle="outline">
              <Radio.Button value={ResponseType.TEXT}>Text</Radio.Button>
              <Radio.Button value={ResponseType.QUICK_REPLY}>
                Quick reply
              </Radio.Button>
              <Radio.Button value={ResponseType.NONE}>None</Radio.Button>
            </Radio.Group>
          </Form.Item>
        )}

        {/* dynamicaly add buttons for quick reply options */}
        {responseType === ResponseType.QUICK_REPLY && !isUseAsCategory && (
          <>
            <Form.Item
              label="Quick reply options"
              labelCol={{ span: 24 }}
              // noStyle
            >
              <Form.Item
                name="branch_after"
                valuePropName="checked"
                style={{ marginBottom: 0 }}
              >
                <Checkbox>Branch after</Checkbox>
              </Form.Item>
              <Form.List name="quick_reply_options">
                {(fields, { add, remove }) => (
                  <div>
                    {fields.map((field, index) => (
                      <Row key={field.key}>
                        <Col span={16}>
                          <Form.Item
                            {...field}
                            required={false}
                            name={[field.name, "value"]}
                            style={{ marginBottom: 8 }}
                          >
                            <Input placeholder="Enter quick reply option" />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Button
                            danger
                            style={{ height: 16 }}
                            type="link"
                            size="small"
                            onClick={() => remove(field.name)}
                          >
                            <MinusCircleOutlined />
                          </Button>
                        </Col>
                      </Row>
                    ))}
                    {/* {fields.length < 2 && !message.branch_after && ( */}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block>
                        Add quick reply option
                      </Button>
                    </Form.Item>
                    {/* )} */}
                  </div>
                )}
              </Form.List>
            </Form.Item>
          </>
        )}

        {/* additional checkboxes */}
        <Form.Item
          labelCol={{ span: 24 }}
          label="Additional options"
          name="additional_options"
        >
          <Form.Item
            name={["additional_options", "use_as_category"]}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox>Use as category</Checkbox>
          </Form.Item>
          <Form.Item
            name={["additional_options", "use_as_quote"]}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox>Use as quote</Checkbox>
          </Form.Item>
          <Form.Item
            name={["additional_options", "use_as_title"]}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox>Use as title</Checkbox>
          </Form.Item>
          <Form.Item
            name={["additional_options", "use_in_summary"]}
            valuePropName="checked"
            style={{ marginBottom: 0 }}
          >
            <Checkbox>Use in summary</Checkbox>
          </Form.Item>
          <Form.Item
            name={["additional_options", "summary_title"]}
            style={{ marginBottom: 0 }}
          >
            <Input placeholder="Enter summary title" />
          </Form.Item>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default MessageForm;
