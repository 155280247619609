// search component

// imports
import * as React from "react";
import { Input } from "antd";

let typingTimeout: any;
let doneTypingInterval: number = 500;

type Props = {
  onSearch: (value: string) => void;
  value: string;
};

const Search: React.FC<Props> = ({ onSearch, value }) => {
  const [search, setSearch] = React.useState<string>(value);

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  React.useEffect(() => {
    // clear timeout if it has already been set
    clearTimeout(typingTimeout);

    // start timer again
    typingTimeout = setTimeout(() => {
      onSearch(search);
    }, doneTypingInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Input.Search
      placeholder="Search"
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleSearch(e.target.value as string)
      }
      // onKeyUp={handleKeyUp}
      value={search}
      allowClear={true}
    />
  );
};

export default Search;
