import * as React from "react";
import {
  Modal,
  Form,
  Input,
  Row,
  Space,
  Switch,
  Button,
  Typography,
  message,
  Select,
} from "antd";
import { CaminosService } from "../caminos.service";
import { FileUrl } from "../../../shared/interfaces/file-url.interface";
import ImageUpload from "../../../shared/components/image-upload";
import { CaminosContext } from "../caminos.context";
import {
  ICamino,
  ICaminoCategory,
  ICaminoMessage,
  IEdge,
} from "../caminos.interfaces";

const caminosService = new CaminosService();

type Props = {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  callback?: (data: ICamino) => void;
};

const EditCaminoModal: React.FC<Props> = ({
  visible,
  setVisible,
  callback,
}) => {
  const { camino, setCamino, parseNodesToMessages, parseEdgesToCaminoEdges } =
    React.useContext(CaminosContext);

  const [categories, setCategories] = React.useState<ICaminoCategory[]>([]);

  const [form] = Form.useForm();

  const [icon, setIcon] = React.useState<FileUrl | undefined>(undefined);
  const [cover, setCover] = React.useState<FileUrl | undefined>(undefined);

  // get categories on mount
  React.useEffect(() => {
    caminosService.getCategories({}).then((response) => {
      setCategories(response.data);
    });
  }, []);

  React.useEffect(() => {
    form.setFieldsValue(camino);
    if (camino?.icon) {
      setIcon({
        file: undefined,
        url: camino.icon,
      });
    }
    if (camino?.cover) {
      setCover({
        file: undefined,
        url: camino.cover,
      });
    }
  }, [camino, form]);

  const handleUpdate = (values: any) => {
    if (!camino?._id) return;

    let formData: FormData = new FormData();

    if (icon?.file) {
      formData.append("icon", icon.file);
    }
    if (icon?.delete) {
      formData.append("delete_icon", "true");
    }
    if (cover?.file) {
      formData.append("cover", cover.file);
    }
    if (cover?.delete) {
      formData.append("delete_cover", "true");
    }
    formData.append("name", values.name);
    if (values.description) {
      formData.append("description", values.description);
    }
    if (values.end_title) {
      formData.append("end_title", values.end_title);
    }
    if (values.end_message) {
      formData.append("end_message", values.end_message);
    }
    formData.append("public", values.public);
    formData.append("journey_category", values.journey_category);

    // call service to update
    caminosService
      .update(camino._id, formData)
      .then((response) => {
        // set camino data
        form.setFieldsValue(response.data);

        // set camino from response.data, but leave messages as is
        const messages: Partial<ICaminoMessage>[] = parseNodesToMessages();
        // and save edges
        const edges: Partial<IEdge>[] = parseEdgesToCaminoEdges();

        setCamino({
          ...response.data,
          messages,
          edges,
        });

        if (response.data.icon) {
          setIcon({
            file: undefined,
            url: response.data.icon,
          });
        }
        if (response.data.cover) {
          setCover({
            file: undefined,
            url: response.data.cover,
          });
        }
        if (callback) callback(response.data);
        setVisible(false);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Error updating camino");
        }
      });
  };

  return (
    <Modal
      title="Edit Camino"
      visible={visible}
      onOk={() => null}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={handleUpdate}>
        {/* category select */}
        <Form.Item
          label="Category"
          name="journey_category"
          rules={[{ required: true, message: "Please select a category" }]}
          initialValue={camino?.journey_category}
        >
          <Select
            placeholder="Select a category"
            options={categories.map((category) => ({
              label: category.name,
              value: category._id,
            }))}
          />
        </Form.Item>

        <Form.Item label="Name" name="name">
          <Input />
        </Form.Item>

        <Row justify="space-between">
          <Form.Item
            label="Icon"
            name="icon"
            valuePropName="fileList"
            extra={
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                256x256
                <br />
                jpg, jpeg, png
                <br />
                max size: 0.5MB
              </Typography.Text>
            }
          >
            <ImageUpload
              name="icon"
              height={128}
              width={128}
              fit="contain"
              image={icon}
              setImage={setIcon}
            />
          </Form.Item>
          <Form.Item
            label="Cover"
            name="cover"
            valuePropName="fileList"
            extra={
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                1024x512
                <br />
                jpg, jpeg, png
                <br />
                max size: 1MB
              </Typography.Text>
            }
          >
            <ImageUpload
              name="cover"
              height={128}
              width={256}
              fit="cover"
              image={cover}
              setImage={setCover}
            />
          </Form.Item>
        </Row>

        <Form.Item label="End message title" name="end_title">
          <Input />
        </Form.Item>

        <Form.Item label="End message" name="end_message">
          <Input.TextArea />
        </Form.Item>

        <Form.Item label="Public" name="public" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Space direction="horizontal">
              <Button
                type="default"
                htmlType="button"
                onClick={() => setVisible(false)}
              >
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Space>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditCaminoModal;
