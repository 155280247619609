// covers service

// imports
import { httpClient } from "../../shared/services/http-client";

// covers service class
export class CoversService {
  // get covers
  public async getCovers(): Promise<any> {
    return await httpClient.get("/covers");
  }

  // make cover active
  public async makeCoverActive(id: string): Promise<any> {
    return await httpClient.put(`/covers/${id}/active`);
  }

  // create cover
  public async createCover(data: any): Promise<any> {
    return await httpClient.post("/covers", data);
  }
}
