// flowchart editor

import * as React from "react";
import ReactFlow, { MiniMap, Controls } from "react-flow-renderer";
import { CaminosContext } from "../caminos.context";
import MessageNode from "./message-node.component";

const nodeTypes = { message: MessageNode };
const snapGrid: [number, number] = [20, 20];

const Chart: React.FC = () => {
  const { nodes, edges, onEdgesChange, onNodesChange, onConnect } =
    React.useContext(CaminosContext);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        borderTop: "1px solid #ccc",
      }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        connectionLineStyle={{
          stroke: "#1890ff",
          strokeWidth: 4,
        }}
        snapGrid={snapGrid}
        snapToGrid={true}
        minZoom={0.1}
        maxZoom={2}
        fitView={true}
      >
        <MiniMap nodeColor="#dad5e399" />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default Chart;
