// imports
import * as React from "react";
import { ReactFlowProvider } from "react-flow-renderer";
import { Route, Routes, Navigate } from "react-router-dom";
import { CaminosProvider } from "./caminos.context";
import CaminosPage from "./pages/caminos.page";
import CategoriesPage from "./pages/categories.page";
import CaminoEditorPage from "./pages/editor.page";
import CaminoListPage from "./pages/list.page";

const CaminosModule: React.FC = () => {
  return (
    <ReactFlowProvider>
      <CaminosProvider>
        <Routes>
          <Route path="/" element={<CategoriesPage />} />
          <Route path="/:id" element={<CaminosPage />} />
          <Route path="/:categoryId/:id" element={<CaminoEditorPage />} />
          <Route path="*" element={<Navigate to="/caminos" />} />
        </Routes>
      </CaminosProvider>
    </ReactFlowProvider>
  );
};

export default CaminosModule;
