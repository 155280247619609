// image upload component

// imports
import * as React from "react";
import { Upload, message, Button } from "antd";
import { FileUrl } from "../interfaces/file-url.interface";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";

const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const beforeUpload = (file: File) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must be smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

type Props = {
  name: string;
  width?: number;
  height?: number;
  fit?: "cover" | "contain";
  image?: FileUrl;
  setImage: (image: FileUrl) => void;
};

const ImageUpload: React.FC<Props> = (props) => {
  const handleChange = (info: any) => {
    // if error, show error message
    if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      return;
    }
    // get file url
    getBase64(info.file.originFileObj as File).then((fileUrl) => {
      props.setImage({
        file: info.file.originFileObj as File,
        url: fileUrl as string,
        delete: false,
      });
    });
  };

  const handleDelete = () => {
    props.setImage({
      file: undefined,
      url: undefined,
      delete: true,
    });
  };

  return (
    <div>
      <Upload.Dragger
        name={props.name}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        customRequest={() => {}}
        multiple={false}
        showUploadList={false}
        style={{
          width: props.width || "100%",
        }}
      >
        {props.image?.url ? (
          <img
            alt={props.name}
            src={props.image.url}
            style={{
              width: props.width ? props.width - 2 : "100%",
              height: props.height ? props.height - 4 : "auto",
              objectFit: props.fit || "cover",
              borderRadius: 6,
            }}
          />
        ) : (
          <div
            style={{
              width: props.width || "100%",
              height: props.height || 124,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UploadOutlined size={48} />
          </div>
        )}
      </Upload.Dragger>
      {props.image?.url && (
        <Button
          type="text"
          danger
          onClick={handleDelete}
          size="small"
          icon={<DeleteOutlined />}
        >
          Remove
        </Button>
      )}
    </div>
  );
};

export default ImageUpload;
