import * as React from "react";
import {
  List,
  Upload,
  message,
  PageHeader,
  Image,
  Card,
  Typography,
  Button,
  Popconfirm,
  Spin,
  UploadProps,
  Tooltip,
} from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  InboxOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import { IFile } from "../files.interfaces";
import { FilesService } from "../files.service";
import AudioComponent from "../components/audio.component";

const filesService = new FilesService();

const getBase64 = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const FilesPage: React.FC = () => {
  const [files, setFiles] = React.useState<IFile[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [replaceName, setReplaceName] = React.useState<string>("");

  React.useEffect(() => {
    filesService.getFiles().then((res) => {
      setFiles(res.data);
    });
  }, []);

  const onUpload = (info: any) => {
    // if error, show error message
    if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      return;
    }

    let fl: File;

    if (info.file.originFileObj) {
      fl = info.file.originFileObj;
    } else {
      fl = info.file;
    }

    // file name must have only letters and underscores
    // otherwise show error
    // get name of file, remove extension
    const fileName = fl.name.replace(/\.[^/.]+$/, "");

    if (/[^a-zA-Z_0-9]/.test(fileName)) {
      message.error(
        `${fileName} file name is invalid. Can contain only letters, numbers and _`
      );
      return;
    }

    let formData = new FormData();
    formData.append("file", fl);

    setIsLoading(true);

    filesService
      .createFile(formData)
      .then((res) => {
        setFiles([...files, res.data]);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to upload file");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const copyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url);
    message.success("Copied to clipboard");
  };

  const handleDelete = (id: string) => {
    filesService
      .deleteFile(id)
      .then((res) => {
        message.success("File deleted");
        setFiles(files.filter((file) => file._id !== id));
      })
      .catch((err) => {
        message.error("Error deleting file");
      });
  };

  const onReplace = (info: any) => {
    // if error, show error message
    if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
      return;
    }

    // check if extensions are the same
    const uploadExtension: string = info.file.name.split(".").pop() as string;
    const replaceExtension: string = replaceName.split(".").pop() as string;
    if (uploadExtension !== replaceExtension) {
      message.error(
        "File format must be the same as the file you want to replace"
      );
      return;
    }

    let fl: File;

    if (info.file.originFileObj) {
      fl = info.file.originFileObj;
    } else {
      fl = info.file;
    }

    // file name must have only letters and underscores and numbers
    // otherwise show error
    // get name of file, remove extension
    const fileName = fl.name.replace(/\.[^/.]+$/, "");

    if (/[^a-zA-Z_0-9]/.test(fileName)) {
      message.error(
        `${fileName} file name is invalid. Can contain only letters, numbers and _`
      );
      return;
    }

    // split replace name by "/"
    // take last part of split
    // split last part by "."
    // take only name
    if (!replaceName) {
      return;
    }

    // alert(name);

    // return;

    let formData = new FormData();
    formData.append("file", fl);
    formData.append("replace_name", replaceName);

    setIsLoading(true);

    filesService
      .createFile(formData)
      .then((res) => {
        // find file that has url same as replaceName and replace it
        setFiles(
          files.map((file) => {
            if (file.url === replaceName) {
              return res.data;
            }
            return file;
          })
        );
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to upload file");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div>
      <PageHeader title="Media Library" />
      <div style={{ margin: 24 }}>
        <Upload.Dragger
          name="file"
          multiple={false}
          action={undefined}
          customRequest={onUpload}
          // onChange={(info) => {
          //   console.log(info);
          // }}
          style={{ padding: "24px" }}
          showUploadList={false}
          disabled={isLoading}
        >
          <p className="ant-upload-drag-icon">
            {isLoading ? <Spin size="large" /> : <InboxOutlined />}
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Upload.Dragger>

        <List
          style={{ marginTop: "24px" }}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 3,
            lg: 4,
            xl: 5,
            xxl: 6,
          }}
          dataSource={files}
          renderItem={(file) => (
            <List.Item>
              <Card
                cover={
                  file.type === "image" ? (
                    <Image
                      src={file.url + `?${new Date().getTime()}`}
                      alt={file.name}
                      style={{
                        maxWidth: "100%",
                        height: 100,
                        objectFit: "contain",
                      }}
                    />
                  ) : file.type === "audio" ? (
                    <AudioComponent
                      url={file.url + `?${new Date().getTime()}`}
                    />
                  ) : null
                }
                title={file.name}
                actions={[
                  <Tooltip title="Copy to clipboard" placement="bottom">
                    <Button
                      icon={<CopyOutlined />}
                      type="link"
                      onClick={() => copyToClipboard(file.url)}
                    >
                      {/* Copy */}
                    </Button>
                  </Tooltip>,
                  <Upload customRequest={onReplace} showUploadList={false}>
                    <Tooltip title="Replace" placement="bottom">
                      <Button
                        icon={<SwapOutlined />}
                        type="link"
                        onClick={() => setReplaceName(file.url)}
                      >
                        {/* Replace */}
                      </Button>
                    </Tooltip>
                  </Upload>,
                  <Tooltip title="Delete" placement="bottom">
                    <Popconfirm
                      title="Are you sure want to delete this file?"
                      onConfirm={() => handleDelete(file._id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button icon={<DeleteOutlined />} type="link" danger>
                        {/* Delete */}
                      </Button>
                    </Popconfirm>
                  </Tooltip>,
                ]}
                bodyStyle={{ padding: "0" }}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default FilesPage;
