import { Button, Divider, message, PageHeader, Popconfirm, Table } from "antd";
import type { ColumnsType } from "antd/lib/table";
import * as React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DraggableBodyRow from "../components/row.component";
import { ICaminoCategory } from "../caminos.interfaces";
import { CaminosService } from "../caminos.service";
import CategoryEditModal from "../components/category-edit-modal.component";
import CategoryCreateModal from "../components/category-create-modal.component copy";
import { Link } from "react-router-dom";

const caminosService = new CaminosService();

const CategoriesPage: React.FC = () => {
  const [data, setData] = React.useState<ICaminoCategory[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [category, setCategory] = React.useState<ICaminoCategory | undefined>(
    undefined
  );
  const [editModalVisible, setEditModalVisible] =
    React.useState<boolean>(false);
  const [createModalVisible, setCreateModalVisible] =
    React.useState<boolean>(false);

  const columns: ColumnsType<ICaminoCategory> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/caminos/${record._id}`}>{text}</Link>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      align: "right",
      render: (category: ICaminoCategory) => (
        <div>
          <Button
            style={{ padding: 0 }}
            type="link"
            onClick={() => {
              setCategory(category);
              setEditModalVisible(true);
            }}
          >
            Edit
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure you want to delete this category?"
            onConfirm={() => handleDeleteCategory(category._id)}
            onCancel={() => null}
            okText="Yes"
            cancelText="No"
            placement="topRight"
          >
            <Button style={{ padding: 0 }} type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = React.useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragRow = data[dragIndex];
      console.log(dragRow, hoverIndex);
    },
    [data]
  );

  // get data
  React.useEffect(() => {
    setLoading(true);

    caminosService
      .getCategories({})
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to get categories");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // handle edit category
  const handleEditCategory = (cat: ICaminoCategory) => {
    caminosService
      .updateCategory(category?._id as string, cat)
      .then((res) => {
        const index = data.findIndex((c) => c._id === res.data._id);
        if (index >= 0) {
          data[index] = res.data;
          setData([...data]);
        }
        setEditModalVisible(false);
        setCategory(undefined);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to update category");
        }
      });
  };

  // handle delete category
  const handleDeleteCategory = (id: string) => {
    caminosService
      .deleteCategory(id)
      .then(() => {
        const index = data.findIndex((c) => c._id === id);
        if (index >= 0) {
          data.splice(index, 1);
          setData([...data]);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to delete category");
        }
      });
  };

  // handle create category
  const handleCreateCategory = (cat: ICaminoCategory) => {
    caminosService
      .createCategory(cat)
      .then((res) => {
        setData([...data, res.data]);
        setCreateModalVisible(false);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to create category");
        }
      });
  };

  return (
    <div>
      <PageHeader
        title="Categories"
        extra={[
          <Button
            key="add"
            type="primary"
            onClick={() => setCreateModalVisible(true)}
          >
            Add Category
          </Button>,
        ]}
      />
      <DndProvider backend={HTML5Backend}>
        <Table
          loading={loading}
          pagination={false}
          style={{
            margin: 24,
          }}
          columns={columns}
          dataSource={data}
          // components={components}
          onRow={(_, index) => {
            const attr = {
              index,
              moveRow,
            };
            return attr as React.HTMLAttributes<any>;
          }}
        />
      </DndProvider>

      <CategoryEditModal
        visible={editModalVisible}
        category={category}
        onCancel={() => setEditModalVisible(false)}
        onSubmit={handleEditCategory}
      />

      <CategoryCreateModal
        visible={createModalVisible}
        onCancel={() => setCreateModalVisible(false)}
        onSubmit={handleCreateCategory}
      />
    </div>
  );
};

export default CategoriesPage;
