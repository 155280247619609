// imports
import { httpClient } from "../../shared/services/http-client";

// areas service class
export class AreasService {
  // get all
  public async getAll(): Promise<any> {
    return await httpClient.get("/areas");
  }

  // create
  public async create(data: any): Promise<any> {
    return await httpClient.post(`/areas`, data);
  }

  // update
  public async update(id: string, data: any): Promise<any> {
    return await httpClient.put(`/areas/${id}`, data);
  }

  // get one
  public async getOne(id: string): Promise<any> {
    return await httpClient.get(`/areas/${id}`);
  }

  // delete
  public async delete(id: string): Promise<any> {
    return await httpClient.delete(`/areas/${id}`);
  }

  // update many
  public async updateMany(data: any): Promise<any> {
    return await httpClient.put(`/areas`, data);
  }
}
