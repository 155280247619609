import * as React from "react";
import { Typography, Alert } from "antd";

const EditorInfo: React.FC = () => {
  return (
    <div>
      <Alert
        showIcon={false}
        message={
          <>
            <Typography.Text type="secondary">
              To use user's response from one message in another, copy message
              id from the top of the card and paste it inside the bot's message
              text between curly braces. To use user's name, put fname inside
              the curly braces.
              <br />
              <br />
              Input:{" "}
              <Typography.Text italic={true} type="secondary">
                Hello{" "}
                <Typography.Text type="secondary" strong>
                  {"{"}fname{"}"},
                </Typography.Text>{" "}
                your selected category is{" "}
                <Typography.Text type="secondary" strong>
                  {"{"}9976577c77c7ee5476f3ff4a{"}"}
                </Typography.Text>
                .
              </Typography.Text>
              <br />
              Output:{" "}
              <Typography.Text italic={true} type="secondary">
                Hello{" "}
                <Typography.Text type="secondary" strong>
                  David,
                </Typography.Text>{" "}
                your selected category is{" "}
                <Typography.Text type="secondary" strong>
                  Rage
                </Typography.Text>
                .
              </Typography.Text>
            </Typography.Text>
          </>
        }
        type="info"
        banner={true}
      />
    </div>
  );
};

export default EditorInfo;
