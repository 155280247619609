// forgot password page

// imports
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Typography,
} from "antd";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthService } from "../auth.service";
import logo from "../../../assets/logo.png";

const authService = new AuthService();

const ForgotPage: React.FC = () => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();

  const onFinish = (values: any) => {
    setIsLoading(true);
    localStorage.setItem("email", values.email);
    authService
      .forgotPassword(values.email)
      .then((res) => {
        message.success("Password reset email sent");
        navigate("/verification");
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Password reset failed");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Row style={{ paddingTop: 24 }}>
      <Col
        xs={{ span: 20, offset: 2 }}
        sm={{ span: 12, offset: 6 }}
        md={{ span: 8, offset: 8 }}
        lg={{ span: 6, offset: 9 }}
        xl={{ span: 4, offset: 10 }}
      >
        <Row justify="center" style={{ marginBottom: 24 }}>
          <Avatar src={logo} size={64} style={{ backgroundColor: "white" }} />
        </Row>
        <Typography.Title level={2}>Forgot Password</Typography.Title>
        <Form form={form} name="forgot" layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              {
                type: "email",
                message: "The input is not valid email!",
              },
            ]}
            extra="Verification code will be sent to your email"
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Row justify="space-between" align="middle">
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Submit
              </Button>
              <Link to="/login">Back to login</Link>
            </Row>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ForgotPage;
