import * as React from "react";
import { Image } from "antd";
import AudioComponent from "../../files/components/audio.component";

const extensionToType: { [key: string]: "image" | "audio" } = {
  jpeg: "image",
  jpg: "image",
  png: "image",
  gif: "image",
  svg: "image",
  mp3: "audio",
  wav: "audio",
  ogg: "audio",
  mpeg: "audio",
};

type Props = {
  url: string;
};

const PopoverContent: React.FC<Props> = (props) => {
  const { url } = props;

  if (!url) {
    return null;
  }

  const extension = url.split(".").pop();
  const type = extensionToType[extension as string];

  if (type === "image") {
    return (
      <Image
        src={url + `?${Date.now()}`}
        style={{
          width: 256,
          height: 256,
          objectFit: "contain",
        }}
      />
    );
  } else if (type === "audio") {
    return (
      <div
        style={{
          width: 256,
          height: 100,
        }}
      >
        <AudioComponent url={url + `?${Date.now()}`} />
      </div>
    );
  }

  return null;
};

export default PopoverContent;
