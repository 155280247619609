// reset password page

// imports
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Typography,
} from "antd";
import * as React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "../../../shared/contexts/user.context";
import { AuthService } from "../auth.service";
import logo from "../../../assets/logo.png";

const authService = new AuthService();

const ResetPage: React.FC = () => {
  const [form] = Form.useForm();

  const { user, logout } = React.useContext(UserContext);

  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();

  const onFinish = (values: any) => {
    setIsLoading(true);
    authService
      .resetPassword(values.password, values.password_confirmation)
      .then((res) => {
        message.success("Password reset successful");
        logout();
        navigate("/login");
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Password reset failed");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <Row style={{ paddingTop: 24 }}>
      <Col
        xs={{ span: 20, offset: 2 }}
        sm={{ span: 12, offset: 6 }}
        md={{ span: 8, offset: 8 }}
        lg={{ span: 6, offset: 9 }}
        xl={{ span: 4, offset: 10 }}
      >
        <Row justify="center" style={{ marginBottom: 24 }}>
          <Avatar src={logo} size={64} style={{ backgroundColor: "white" }} />
        </Row>
        <Typography.Title level={2}>Reset Password</Typography.Title>
        <Form form={form} name="reset" layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
              {
                min: 8,
                message: "Password must be at least 8 characters long",
              },
              {
                pattern:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+=-{}[\]|:'"<>?,.`~\\/]).*$/,
                message:
                  "Password must contain at least 1 lowercase, 1 uppercase, 1 number. Password cannot contain: ` ~  /",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="password_confirmation"
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    "The two passwords that you entered do not match!"
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Reset Password
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default ResetPage;
