// covers service

// imports
import { httpClient } from "../../shared/services/http-client";

// covers service class
export class FilesService {
  // get files
  public async getFiles(): Promise<any> {
    return await httpClient.get("/files");
  }

  // create file
  public async createFile(data: any): Promise<any> {
    return await httpClient.post("/files", data);
  }

  // delete file
  public async deleteFile(id: string): Promise<any> {
    return await httpClient.delete(`/files/${id}`);
  }
}
