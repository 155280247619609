import * as React from "react";
import { Modal, Form, Button, Input } from "antd";

type Props = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: any) => void;
};

const CategoryCreateModal: React.FC<Props> = ({
  visible,
  onCancel,
  onSubmit,
}) => {
  const [form] = Form.useForm();

  // reset form when modal closes
  React.useEffect(() => {
    form.resetFields();
  }, [visible]);

  return (
    <Modal
      title="Create category"
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={(data) => {
          onSubmit(data);
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input the category name",
            },
            {
              max: 30,
              message: "Max length is 30",
            },
          ]}
        >
          <Input max={30} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CategoryCreateModal;
