// caminos interfaces

export enum MessageType {
  TEXT = "text",
  IMAGE = "image",
  AUDIO = "audio",
}

export enum ResponseType {
  TEXT = "text",
  QUICK_REPLY = "quick_reply",
  NONE = "none",
}

type PreviousMessage = {
  _id?: string;
  quick_reply_option?: IQuickReplyOption;
};

type Position = {
  x: number;
  y: number;
};

export interface IEdge {
  _id: string;
  source: string;
  target: string;
  source_handle?: string;
  target_handle?: string;
}

export interface IQuickReplyOption {
  _id?: string;
  value: string;
}

export interface IAdditionalOptions {
  use_as_quote?: boolean;
  use_as_title?: boolean;
  use_as_category?: boolean;
  use_as_todo?: boolean;
  use_in_summary?: boolean;
}

export interface ICaminoMessage {
  _id?: string;
  title: string;
  message_type: MessageType;
  response_type: ResponseType;
  message_text?: string;
  message_file?: string;
  delay: number;
  quick_reply_options?: IQuickReplyOption[];
  branch_after?: boolean;
  additional_options: IAdditionalOptions;
  previous_message?: PreviousMessage;
  created_at: Date;
  updated_at: Date;
  position?: Position;
  is_first?: boolean;
}

export interface ICamino {
  _id: string;
  name: string;
  description?: string;
  icon?: string;
  cover?: string;
  public: boolean;
  messages?: Partial<ICaminoMessage>[];
  end_message?: string;
  end_title?: string;
  edges?: Partial<IEdge>[];
  created_at: Date;
  updated_at: Date;
  journey_category?: string;
  order?: number;
}

export interface ICaminoCategory {
  _id: string;
  name: string;
  journeys?: ICamino[];
  created_at: Date;
  updated_at: Date;
}
