// covers list and upload page

// imports
import * as React from "react";
import {
  Upload,
  Row,
  Col,
  PageHeader,
  Form,
  message,
  Typography,
  Image,
  Button,
} from "antd";
import { ICover } from "../covers.interfaces";
import { CoversService } from "../covers.service";
import { RcFile } from "antd/lib/upload";
import { InboxOutlined } from "@ant-design/icons";

// service
const coversService = new CoversService();

// get base64 data from file
const getBase64 = (file: File, callback: (data: any) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file);
};

// before upload
const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

// component
const CoversListPage: React.FC = () => {
  // state
  const [covers, setCovers] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingAll, setIsLoadingAll] = React.useState<boolean>(true);

  // handle change
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setIsLoading(true);
      return;
    }

    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl: string) => {
        setIsLoading(false);
      });
    }

    if (info.file.status === "error") {
      setIsLoading(false);
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  // upload cover
  const uploadCover = (file: RcFile) => {
    setIsLoading(true);

    const formData: FormData = new FormData();
    formData.append("cover", file);

    coversService
      .createCover(formData)
      .then((response) => {
        message.success("Cover uploaded");
        setIsLoading(false);
        const newCover = {
          uid: response.data._id,
          name: response.data.url,
          status: "done",
          url: response.data.url,
          active: response.data.active,
        };
        setCovers([newCover, ...covers]);
      })
      .catch((error) => {
        message.error(error.response?.data?.message || "Error uploading cover");
        setIsLoading(false);
      });
  };

  // get covers
  React.useEffect(() => {
    setIsLoadingAll(true);

    coversService
      .getCovers()
      .then((response) => {
        setIsLoadingAll(false);
        setCovers(
          response.data.map((cover: ICover) => ({
            uid: cover._id,
            name: cover.url,
            status: "done",
            url: cover.url,
            active: cover.active,
          }))
        );
      })
      .catch((error) => {
        message.error(error.response?.data?.message || "Error getting covers");
        setIsLoadingAll(false);
      });
  }, []);

  const handleMakeActive = (uid: string) => {
    coversService
      .makeCoverActive(uid)
      .then(() => {
        setCovers(
          covers.map((cover) => {
            if (cover.active) {
              cover.active = false;
            } else if (cover.uid === uid) {
              cover.active = true;
            }
            return cover;
          })
        );

        message.success("Cover made active");
      })
      .catch((error) => {
        message.error(
          error.response?.data?.message || "Error making cover active"
        );
      });
  };

  if (isLoadingAll) {
    return <Typography.Paragraph>Loading...</Typography.Paragraph>;
  }

  return (
    <div>
      <PageHeader title="Covers" />
      {/* upload component */}
      <Row style={{ margin: 24 }}>
        <Col span={24}>
          <Upload.Dragger
            name="cover"
            listType="picture-card"
            multiple={false}
            className="avatar-uploader"
            showUploadList={false}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            customRequest={(options: any) => uploadCover(options.file)}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from
              uploading company data or other band files
            </p>
          </Upload.Dragger>
        </Col>
      </Row>

      {/* list component */}
      <Row gutter={0}>
        {covers.map((cover) => (
          <Col key={cover.uid} xs={24} sm={12} md={8} lg={6} xl={4} xxl={2}>
            <Image
              src={"http://localhost:3001/" + cover.url}
              width={200}
              height={200}
              preview={false}
              style={{
                // object fit
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={() => handleMakeActive(cover.uid)}
            />
            {/* overlay if cover is not active */}
            {!cover.active && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                }}
              />
            )}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default CoversListPage;
