// user list page shown in table

// imports
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import {
  Avatar,
  message,
  PageHeader,
  Switch,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Divider,
  Popconfirm,
  Typography,
  Badge,
} from "antd";
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import ImageUpload from "../../../shared/components/image-upload";
import Search from "../../../shared/components/search.component";
import { useQuery } from "../../../shared/hooks/use-query";
import { FileUrl } from "../../../shared/interfaces/file-url.interface";
import { IPagination } from "../../../shared/interfaces/pagination.interface";
import { IUser } from "../users.interfaces";
import { UsersService } from "../users.service";

const usersService = new UsersService();

const UserListPage: React.FC = () => {
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [pagination, setPagination] = React.useState<IPagination>({
    page: 1,
    limit: 10,
    total: 0,
    pages: 0,
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [query, setQuery] = useQuery();
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [shouldReload, setShouldReload] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<IUser | undefined>(undefined);

  const [avatar, setAvatar] = React.useState<FileUrl | undefined>(undefined);
  const [editAvatar, setEditAvatar] = React.useState<FileUrl | undefined>(
    undefined
  );

  const [form] = Form.useForm();
  const [editForm] = Form.useForm();

  // set should reload on search params change
  React.useEffect(() => {
    setShouldReload(true);
  }, [searchParams]);

  React.useEffect(() => {
    if (isLoading) return;

    if (!shouldReload) return;

    setIsLoading(true);

    usersService
      .getAll(query)
      .then((response) => {
        setUsers(response.data?.users);
        setPagination(response.data?.pagination);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to get users");
        }
      })
      .finally(() => {
        setIsLoading(false);
        setShouldReload(false);
      });
  }, [shouldReload]);

  const handleAppAccess = (_id: string) => {
    usersService
      .toggleAppAccess(_id)
      .then(() => {
        message.success("App access updated");
        // find user and update app access
        // set users to new state
        setUsers(
          users.map((user) => {
            if (user._id === _id) {
              user.has_app_access = !user.has_app_access;
            }
            return user;
          })
        );
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to update app access");
        }
      });
  };

  const handleCreate = (data: Partial<IUser>) => {
    const formData = new FormData();

    if (avatar?.file) {
      formData.append("avatar", avatar?.file);
    }
    if (data.name) {
      formData.append("name", data.name);
    }
    if (data.email) {
      formData.append("email", data.email);
    }

    usersService
      .create(formData)
      .then(() => {
        message.success("User created");
        setIsModalVisible(false);
        // clear form
        form.resetFields();
        setAvatar(undefined);

        setQuery({ ...query, page: "1" });
        setIsModalVisible(false);
        setShouldReload(true);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to create user");
        }
      });
  };

  React.useEffect(() => {
    if (!user) {
      setEditAvatar(undefined);
    }
  }, [user]);

  React.useEffect(() => {
    if (!isModalVisible) {
      setAvatar(undefined);
    }
  }, [isModalVisible]);

  const handleUpdate = (data: Partial<IUser>) => {
    const formData = new FormData();

    if (editAvatar?.file) {
      formData.append("avatar", editAvatar?.file);
    }
    if (data.name) {
      formData.append("name", data.name);
    }
    if (editAvatar?.delete) {
      formData.append("delete_avatar", "true");
    }

    usersService
      .update(user?._id as string, formData)
      .then(() => {
        message.success("User updated");
        setIsModalVisible(false);
        // clear form
        editForm.resetFields();

        setShouldReload(true);
        setUser(undefined);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to update user");
        }
      });
  };

  const handleDelete = (_id: string) => {
    usersService
      .delete(_id)
      .then(() => {
        message.success("User deleted");
        setShouldReload(true);
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Failed to delete user");
        }
      });
  };

  return (
    <div>
      <PageHeader
        title="Users"
        extra={[
          <Search
            key="search"
            onSearch={(value) => setQuery({ ...query, search: value })}
            value={query.search || ""}
          />,
          <Button
            key="add"
            type="primary"
            onClick={() => setIsModalVisible(true)}
          >
            Add User
          </Button>,
        ]}
      />

      <Table
        rowKey="_id"
        dataSource={users}
        style={{
          margin: 24,
        }}
        columns={[
          {
            title: "Avatar",
            key: "avatar",
            render: (user: IUser) => (
              <Badge
                count={user.role === "admin" ? "admin" : ""}
                status="warning"
              >
                {user.avatar ? (
                  <Avatar src={user.avatar} />
                ) : (
                  <Avatar
                    src={`https://avatars.dicebear.com/api/identicon/${user._id}.svg`}
                  />
                )}
              </Badge>
            ),
          },
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (email: string) => <a href={`mailto:${email}`}>{email}</a>,
          },
          {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
            render: (phone: string) => <a href={`tel:${phone}`}>{phone}</a>,
          },
          {
            title: "Password",
            key: "password",
            align: "center",
            render: (user: IUser) => (
              <div>
                {user.password_set ? (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                  <CloseCircleTwoTone twoToneColor="#eb2f96" />
                )}
              </div>
            ),
          },
          {
            title: "App access",
            key: "has_app_access",
            align: "center",
            render: (user: IUser) => (
              <div>
                <Switch
                  checked={user.has_app_access}
                  onClick={() => handleAppAccess(user._id)}
                />
              </div>
            ),
          },
          {
            title: "Actions",
            key: "actions",
            align: "right",
            render: (user: IUser) => (
              <div>
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={() => {
                    setUser(user);
                    editForm.setFieldsValue({
                      name: user.name,
                      email: user.email,
                    });
                    if (user?.avatar) {
                      setEditAvatar({
                        file: undefined,
                        url: user.avatar,
                      });
                    }
                  }}
                >
                  Edit
                </Button>
                <Divider type="vertical" />
                <Popconfirm
                  title="Are you sure you want to delete this user?"
                  onConfirm={() => handleDelete(user._id)}
                  onCancel={() => null}
                  okText="Yes"
                  cancelText="No"
                  placement="topRight"
                >
                  <Button style={{ padding: 0 }} type="link" danger>
                    Delete
                  </Button>
                </Popconfirm>
              </div>
            ),
          },
        ]}
        loading={isLoading}
        pagination={{
          current: pagination.page,
          pageSize: pagination.limit,
          total: pagination.total,
          onChange: (page: number) => {
            setQuery({
              ...query,
              page: page.toString(),
            });
          },
          showSizeChanger: false,
        }}
      />

      {/* create user modal */}
      <Modal
        title="Create user"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form
          name="create-user"
          onFinish={handleCreate}
          layout="vertical"
          form={form}
        >
          <Form.Item
            label="Avatar"
            name="avatar"
            valuePropName="fileList"
            extra={
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                256x256
                <br />
                jpg, jpeg, png
                <br />
                max size: 0.5MB
              </Typography.Text>
            }
          >
            <ImageUpload
              name="avatar"
              height={128}
              width={128}
              fit="contain"
              image={avatar}
              setImage={setAvatar}
            />
          </Form.Item>
          <Form.Item
            label="First name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input user's name",
              },
            ]}
          >
            <Input placeholder="Enter user's name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input a valid email",
              },
            ]}
          >
            <Input type="email" name="email" placeholder="Enter email" />
          </Form.Item>
          <Form.Item>
            <Button type="default" onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>
            <span style={{ marginRight: 16 }} />
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* edit user modal */}
      <Modal
        title="Edit user"
        visible={!!user}
        onCancel={() => setUser(undefined)}
        footer={null}
      >
        <Form
          name="edit-user"
          onFinish={handleUpdate}
          layout="vertical"
          form={editForm}
        >
          <Form.Item
            label="Avatar"
            name="avatar"
            valuePropName="fileList"
            extra={
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                256x256
                <br />
                jpg, jpeg, png
                <br />
                max size: 0.5MB
              </Typography.Text>
            }
          >
            <ImageUpload
              name="avatar"
              height={128}
              width={128}
              fit="contain"
              image={editAvatar}
              setImage={setEditAvatar}
            />
          </Form.Item>
          <Form.Item
            label="First name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input user's name",
              },
            ]}
          >
            <Input placeholder="Enter user's name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: "email",
                required: true,
                message: "Please input a valid email",
              },
            ]}
          >
            <Input
              disabled
              type="email"
              name="email"
              placeholder="Enter email"
            />
          </Form.Item>
          <Form.Item>
            <Button type="default" onClick={() => setUser(undefined)}>
              Cancel
            </Button>
            <span style={{ marginRight: 16 }} />
            <Button type="primary" htmlType="submit">
              Edit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default UserListPage;
