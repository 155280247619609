// imports
import * as React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import CoverListPage from "./pages/list.page";

const CoversModule: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<CoverListPage />} />
      <Route path="*" element={<Navigate to="/covers" />} />
    </Routes>
  );
};

export default CoversModule;
