// base nav layout

// imports
import { Avatar, Layout, Menu, Button } from "antd";
import { Outlet, Link, useLocation } from "react-router-dom";
import * as React from "react";
import logo from "../assets/logo.png";
import { UserContext } from "../shared/contexts/user.context";

const { Content, Sider } = Layout;

type Route = {
  path: string;
  name: string;
};

const routes: Route[] = [
  {
    path: "/users",
    name: "Users",
  },
  {
    path: "/caminos",
    name: "Caminos",
  },
  {
    path: "/files",
    name: "Media Library",
  },
  {
    path: "/areas",
    name: "Chatbot Categories",
  },
];

const NavLayout: React.FC = () => {
  const location = useLocation();

  const [currentRouteIndex, setCurrentRouteIndex] =
    React.useState<string>("/users");

  // set the current route index
  React.useEffect(() => {
    // get only first path
    const path = location.pathname.split("/")[1];

    setCurrentRouteIndex("/" + path);
  }, [location]);

  const { logout } = React.useContext(UserContext);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        theme="light"
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div
          style={{
            margin: 24,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Avatar src={logo} style={{ backgroundColor: "white" }} />
        </div>
        <Menu theme="light" mode="inline" selectedKeys={[currentRouteIndex]}>
          {routes.map((route: Route, index: number) => (
            <Menu.Item key={route.path}>
              <Link to={route.path}>{route.name}</Link>
            </Menu.Item>
          ))}

          {/* logout */}
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              padding: 8,
              borderTop: "1px solid #e8e8e8",
            }}
          >
            <Menu.Item
              key="logout"
              style={{
                padding: 0,
              }}
            >
              <Button danger type="text" onClick={logout} block>
                Logout
              </Button>
            </Menu.Item>
          </div>
        </Menu>
      </Sider>
      <Content style={{ marginLeft: 200 }}>
        <Outlet />
      </Content>
    </Layout>
  );
};

export default NavLayout;
