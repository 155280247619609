// caminos service

// imports
import { httpClient } from "../../shared/services/http-client";

// caminos service class
export class CaminosService {
  // get all
  public async getAll(): Promise<any> {
    return await httpClient.get("/journeys");
  }

  // create
  public async create(categoryId: string): Promise<any> {
    return await httpClient.post(`/journeys/${categoryId}`);
  }

  // update
  public async update(id: string, data: any): Promise<any> {
    return await httpClient.put(`/journeys/${id}`, data);
  }

  // get one
  public async getOne(id: string): Promise<any> {
    return await httpClient.get(`/journeys/${id}`);
  }

  // create category
  public async createCategory(data: any): Promise<any> {
    return await httpClient.post("/categories", data);
  }

  // update category
  public async updateCategory(id: string, data: any): Promise<any> {
    return await httpClient.put(`/categories/${id}`, data);
  }

  // delete category
  public async deleteCategory(id: string): Promise<any> {
    return await httpClient.delete(`/categories/${id}`);
  }

  // delete
  public async delete(id: string): Promise<any> {
    return await httpClient.delete(`/journeys/${id}`);
  }

  // get categories
  public async getCategories(query: any): Promise<any> {
    return await httpClient.get("/categories", {
      params: query,
    });
  }

  // get journeys by category
  public async getJourneysByCategory(
    categoryId: string,
    query: any
  ): Promise<any> {
    return await httpClient.get(`/categories/${categoryId}/journeys`, {
      params: query,
    });
  }

  // clone journey
  public async cloneJourney(id: string): Promise<any> {
    return await httpClient.post(`/journeys/${id}/clone`);
  }

  // get one category
  public async getOneCategory(id: string): Promise<any> {
    return await httpClient.get(`/categories/${id}`);
  }

  // update camino order
  public async updateCaminoOrder(data: any): Promise<any> {
    return await httpClient.put("/journeys/order", data);
  }

  // update camino message file
  public async updateCaminoMessageFile(
    caminoId: string,
    messageId: string,
    data: any
  ): Promise<any> {
    return await httpClient.put(
      `/journeys/${caminoId}/message/${messageId}/file`,
      data
    );
  }
}
