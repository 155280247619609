// imports
import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ForgotPage from "./pages/forgot.page";
import LoginPage from "./pages/login.page";
import ResetPage from "./pages/reset.page";
import VerificationPage from "./pages/verification.page";

const AuthModule: React.FC = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgot-password" element={<ForgotPage />} />
      <Route path="/verification" element={<VerificationPage />} />
      <Route path="/reset-password" element={<ResetPage />} />
      <Route path="/*" element={<Navigate to="/login" />} />
    </Routes>
  );
};

export default AuthModule;
