import * as React from "react";
import { Modal, Form, Button, Input } from "antd";
import { ICaminoCategory } from "../caminos.interfaces";

type Props = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (values: any) => void;
  category?: ICaminoCategory;
};

const CategoryEditModal: React.FC<Props> = ({
  visible,
  onCancel,
  onSubmit,
  category,
}) => {
  const [form] = Form.useForm();

  // fill form with category data
  React.useEffect(() => {
    if (category) {
      form.setFieldsValue({
        name: category.name,
      });
    }
  }, [category, form]);

  return (
    <Modal
      title="Edit category"
      visible={visible}
      footer={null}
      onCancel={onCancel}
    >
      <Form form={form} layout="vertical" onFinish={(data) => onSubmit(data)}>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input the category name",
            },
            {
              max: 30,
              message: "Max length is 30",
            },
          ]}
        >
          <Input max={30} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Edit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CategoryEditModal;
