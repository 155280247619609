// user service

// imports
import { httpClient } from "../../shared/services/http-client";

// users service class
export class UsersService {
  // get all
  public async getAll(query: any): Promise<any> {
    return await httpClient.get("/users", {
      params: query,
    });
  }

  // get one
  public async getOne(id: string): Promise<any> {
    return await httpClient.get(`/users/${id}`);
  }

  // create
  public async create(data: any): Promise<any> {
    return await httpClient.post("/users", data);
  }

  // toggle app access
  public async toggleAppAccess(id: string): Promise<any> {
    return await httpClient.put(`/users/${id}/app-access`);
  }

  // update
  public async update(id: string, data: any): Promise<any> {
    return await httpClient.put(`/users/${id}`, data);
  }

  // delete
  public async delete(id: string): Promise<any> {
    return await httpClient.delete(`/users/${id}`);
  }
}
