// auth service

// imports
import { httpClient } from "../../shared/services/http-client";

// auth service class
export class AuthService {
  // login
  public async login(email: string, password: string): Promise<any> {
    return await httpClient.post("/auth/admin/login", {
      email,
      password,
    });
  }

  // forgot password
  public async forgotPassword(email: string): Promise<any> {
    return await httpClient.post("/auth/forgot-password", {
      email,
    });
  }

  // verify
  public async verify(verification_code: string, email: string): Promise<any> {
    return await httpClient.post("/auth/verify", {
      verification_code,
      email,
    });
  }

  // reset password
  public async resetPassword(
    password: string,
    password_confirmation: string
  ): Promise<any> {
    return await httpClient.post("/auth/reset-password", {
      password,
      password_confirmation,
    });
  }
}
