// verification page

// imports
import {
  Avatar,
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Typography,
} from "antd";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../auth.service";
import logo from "../../../assets/logo.png";
import { UserContext } from "../../../shared/contexts/user.context";

const authService = new AuthService();

const VerificationPage: React.FC = () => {
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = React.useState(false);

  const navigate = useNavigate();
  const { login } = React.useContext(UserContext);

  const email = localStorage.getItem("email") || "";

  const onFinish = (values: any) => {
    setIsLoading(true);
    authService
      .verify(values.verification_code, email)
      .then((res) => {
        login(res.data);
        message.success("Verification successful");
        navigate("/reset-password");
      })
      .catch((err) => {
        if (err?.response?.data?.messages?.length > 0) {
          // show error messages
          err.response.data.messages.forEach((msg: string) => {
            message.error(msg);
          });
        } else {
          message.error("Verification failed");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Row style={{ paddingTop: 24 }}>
      <Col
        xs={{ span: 20, offset: 2 }}
        sm={{ span: 12, offset: 6 }}
        md={{ span: 8, offset: 8 }}
        lg={{ span: 6, offset: 9 }}
        xl={{ span: 4, offset: 10 }}
      >
        <Row justify="center" style={{ marginBottom: 24 }}>
          <Avatar src={logo} size={64} style={{ backgroundColor: "white" }} />
        </Row>
        <Typography.Title level={2}>Verification</Typography.Title>
        <Form
          form={form}
          name="verification"
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            label="Verification Code"
            name="verification_code"
            rules={[
              {
                required: true,
                message: "Please input your verification code!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default VerificationPage;
