import { Spin } from "antd";
import * as React from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import "./app.less";
import AreasModule from "./modules/areas/areas.module";
import AuthModule from "./modules/auth/auth.module";
import ResetPage from "./modules/auth/pages/reset.page";
import CaminosModule from "./modules/caminos/caminos.module";
import CoversModule from "./modules/covers/covers.module";
import FilesModule from "./modules/files/files.module";
import UsersModule from "./modules/users/users.module";
import NavLayout from "./nav/layout.nav";
import { UserContext, UserProvider } from "./shared/contexts/user.context";

const Navigation: React.FC = () => {
  const { user, isLoading } = React.useContext(UserContext);

  // if isLoading, show loading screen
  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin />
      </div>
    );
  }

  // if not logged in, show login screen
  if (!user) {
    return (
      <Router>
        <Routes>
          <Route path="/*" element={<AuthModule />} />
        </Routes>
      </Router>
    );
  }

  // if logged in, show journeys screen
  return (
    <Router>
      <Routes>
        <Route path="/" element={<NavLayout />}>
          <Route path="/users/*" element={<UsersModule />} />
          <Route path="/caminos/*" element={<CaminosModule />} />
          <Route path="/covers/*" element={<CoversModule />} />
          <Route path="/files/*" element={<FilesModule />} />
          <Route path="/areas/*" element={<AreasModule />} />

          <Route path="/" element={<Navigate to="/users" />} />
        </Route>
        <Route path="/reset-password" element={<ResetPage />} />
      </Routes>
    </Router>
  );
};

const App: React.FC = () => {
  return (
    <UserProvider>
      <Navigation />
    </UserProvider>
  );
};

export default App;
