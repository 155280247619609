// imports
import * as React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AreasPage from "./pages/areas.page";

const AreasModule: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<AreasPage />} />
      <Route path="*" element={<Navigate to="/areas" />} />
    </Routes>
  );
};

export default AreasModule;
