import * as React from "react";
import { Handle, Position } from "react-flow-renderer";
import { ICaminoMessage, ResponseType } from "../caminos.interfaces";
import MessageForm from "./message-form.component";

type Props = {
  data: Partial<ICaminoMessage>;
  isConnectable: boolean;
};

const handleStyle = {
  width: 16,
  height: 16,
  backgroundColor: "#B8A1E3",
  zIndex: 1,
};

const MessageNode: React.FC<Props> = React.memo(({ data, isConnectable }) => {
  const handles = React.useMemo(
    () => () =>
      data &&
      data.response_type === ResponseType.QUICK_REPLY &&
      data.branch_after &&
      data.quick_reply_options &&
      data.quick_reply_options?.length > 0 ? (
        // map quick reply options to handles
        // in reverse order so that the first one is on top

        data.quick_reply_options.map((option, index) =>
          option?._id ? (
            <div
              key={option._id}
              style={{
                bottom: data.quick_reply_options?.length
                  ? (data.quick_reply_options.length - index) * 16 - 10
                  : -index * 20 + 6,
                right: 10,
                position: "absolute",
                fontSize: 12,
              }}
            >
              <div
                style={{
                  paddingRight: 16,
                  // elipsis
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: 124,
                }}
              >
                {option.value}
              </div>
              <Handle
                key={option._id}
                type="source"
                position={Position.Right}
                style={{
                  ...handleStyle,
                }}
                id={option._id}
              />
            </div>
          ) : null
        )
      ) : (
        // add one handle
        <Handle
          type="source"
          position={Position.Right}
          style={{
            ...handleStyle,
            // top is 100% - height of handle
            top: "calc(100% - 16px)",
            right: 8,
          }}
        />
      ),
    [data]
  );

  return (
    <div>
      <Handle
        type="target"
        position={Position.Left}
        style={{ ...handleStyle, top: 16 + 32, left: 8 }}
      />

      <MessageForm message={data} />

      {handles()}
    </div>
  );
});

export default MessageNode;
