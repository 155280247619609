// user context

// imports
import * as React from "react";
import { IUser } from "../../modules/users/users.interfaces";

// context type
export interface IUserContext {
  user: IUser | null;
  isLoading: boolean;
  login: (user: IUser) => void;
  logout: () => void;
}

// context
export const UserContext = React.createContext<IUserContext>({
  user: null,
  isLoading: false,
  login: () => {},
  logout: () => {},
});

type Props = {
  children: React.ReactNode;
};

// provider
export const UserProvider: React.FC<Props> = ({ children }: Props) => {
  const [user, setUser] = React.useState<IUser | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const login = (user: IUser) => {
    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("token", user.token || "");
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  };

  React.useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setUser(JSON.parse(user));
    }
    setIsLoading(false);
  }, []);

  const value = {
    user,
    isLoading,
    login,
    logout,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
