// use query hook
// syncs query state with url through searchParams from react-router-dom

// imports
import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { IQuery } from "../interfaces/query.interface";

export const useQuery = (): [IQuery, (query: IQuery) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = React.useState<IQuery>({
    page: searchParams.get("page") || "1",
    limit: searchParams.get("limit") || "10",
    search: searchParams.get("search") || "",
    sort: searchParams.get("sort") || "created_at",
    order: searchParams.get("order") || "desc",
  });

  React.useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", query.page);
    params.set("limit", query.limit);
    params.set("search", query.search);
    params.set("sort", query.sort);
    params.set("order", query.order);
    setSearchParams(params);
  }, [query, setSearchParams]);

  return [query, setQuery];
};
