import * as React from "react";
import { Button, Slider } from "antd";
import { PauseCircleFilled, PlayCircleFilled } from "@ant-design/icons";

type Props = {
  url: string;
};

const AudioComponent: React.FC<Props> = ({ url }) => {
  const [trackProgress, setTrackProgress] = React.useState(0);
  const [isPlaying, setIsPlaying] = React.useState(false);

  const audioRef = React.useRef(new Audio(url));
  const intervalRef = React.useRef<any>();

  const { duration } = audioRef.current;

  React.useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  React.useEffect(() => {
    // Pause and clean up on unmount
    const currentAudio = audioRef.current;
    const currentInterval = intervalRef.current;

    return () => {
      currentAudio.pause();
      clearInterval(currentInterval);
    };
  }, []);

  const onScrub = (value: number) => {
    // Clear any timers already running
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setTrackProgress(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    // If not already playing, start
    if (!isPlaying) {
      setIsPlaying(true);
    }
  };

  // Update the progress bar
  React.useEffect(() => {
    const currentAudio = audioRef.current;
    let currentInterval = intervalRef.current;

    if (isPlaying) {
      currentInterval = setInterval(() => {
        setTrackProgress(currentAudio.currentTime);
      }, 1000);
    }

    return () => {
      clearInterval(currentInterval);
    };
  }, [isPlaying]);

  // change isPlaying state when finished
  React.useEffect(() => {
    const currentAudio = audioRef.current;
    const currentInterval = intervalRef.current;

    if (currentAudio.ended) {
      setIsPlaying(false);
      // reset progress bar
      setTrackProgress(0);
    }

    return () => {
      clearInterval(currentInterval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackProgress]);

  return (
    <div
      style={{
        maxWidth: "100%",
        height: 100,
        backgroundColor: "#fafafa",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <audio ref={audioRef}>
        <source src={url} />
      </audio>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Button
          type="link"
          icon={
            isPlaying ? (
              <PauseCircleFilled style={{ fontSize: 32 }} />
            ) : (
              <PlayCircleFilled style={{ fontSize: 32 }} />
            )
          }
          onClick={() => {
            setIsPlaying(!isPlaying);
          }}
          size="large"
        ></Button>
        {/* step as track slider */}
        <Slider
          min={0}
          max={duration}
          value={trackProgress}
          onChange={onScrub}
          onAfterChange={onScrubEnd}
          step={0.01}
          style={{
            width: "calc(100% - 20px)",
            marginTop: 8,
            alignSelf: "center",
          }}
        />

        {/* <input
          type="range"
          value={trackProgress}
          step="0.1"
          min="0"
          max={duration ? duration : `${duration}`}
          className="progress"
          onChange={(e) => onScrub(parseInt(e.target.value))}
          onMouseUp={onScrubEnd}
          onKeyUp={onScrubEnd}
          style={{
            width: "calc(100% - 20px)",
          }}
        /> */}
      </div>
    </div>
  );
};

export default AudioComponent;
