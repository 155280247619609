import { Button, Form, Input, PageHeader, message } from "antd";
import * as React from "react";
import { IArea } from "../areas.interfaces";
import { AreasService } from "../areas.service";

const areasService = new AreasService();

const AreasPage: React.FC = () => {
  const [areas, setAreas] = React.useState<IArea[]>([]);

  React.useEffect(() => {
    areasService.getAll().then((response) => {
      setAreas(response.data);
    });
  }, []);

  const handleSubmit = (data: any) => {
    // data is {_id: value}
    // transform to [{_id: _id, value: value}]
    const newAreas: IArea[] = Object.keys(data).map((key) => {
      return { _id: key, value: data[key] };
    });

    // update areas
    areasService
      .updateMany({ areas: newAreas })
      .then((response) => {
        setAreas(response.data);
        message.success("Categories updated successfully");
      })
      .catch((error) => {
        message.error("Error updating categories");
      });
  };

  return (
    <div>
      <PageHeader title="Chatbot Categories" />
      <Form
        style={{
          padding: 24,
        }}
        layout="vertical"
        wrapperCol={{
          xs: { span: 24 },
          sm: { span: 16 },
          md: { span: 12 },
          lg: { span: 8 },
          xl: { span: 6 },
        }}
        onFinish={handleSubmit}
      >
        {areas.map((area) => (
          <Form.Item name={area._id} initialValue={area.value}>
            <Input />
          </Form.Item>
        ))}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AreasPage;
