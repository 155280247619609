// camino editor page

// imports
import * as React from "react";
import { PageHeader, Button, Layout, Popconfirm, message } from "antd";
import EditorInfo from "../components/editor-info.component";
import EditCaminoModal from "../components/edit-modal.component";
import { CaminosContext } from "../caminos.context";
import { useNavigate, useParams } from "react-router-dom";
import Chart from "../components/chart.component";
import { InfoCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import { CaminosService } from "../caminos.service";

const caminosService = new CaminosService();

const CaminoEditorPage: React.FC = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const { camino, setCamino, getCamino, addMessage, deleteCamino, saveCamino } =
    React.useContext(CaminosContext);

  const [infoVisible, setInfoVisible] = React.useState<boolean>(false);
  const [editModalVisible, setEditModalVisible] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (id) {
      getCamino(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // clean up after unmount
  React.useEffect(() => {
    return () => {
      setCamino({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClone = () => {
    caminosService.cloneJourney(id as string).then((response) => {
      message.success("Camino clonado correctamente");
      navigate(`/caminos/${response.data._id}`);
    });
  };

  return (
    <Layout style={{ height: "100vh", overflow: "hidden" }}>
      <PageHeader
        title={camino?.name}
        onBack={() => navigate(`/caminos/${camino?.journey_category}`)}
        avatar={{ src: camino?.icon }}
        extra={[
          <Button
            key="info"
            onClick={() => setInfoVisible(!infoVisible)}
            type="link"
          >
            {infoVisible ? (
              <InfoCircleFilled color="#1890ff" />
            ) : (
              <InfoCircleOutlined color="#1890ff" />
            )}
          </Button>,
          <Popconfirm
            key="delete"
            title="Are you sure you want to delete this camino?"
            onConfirm={() => deleteCamino(id as string)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger ghost type="default">
              Delete
            </Button>
          </Popconfirm>,
          <Button
            key="edit"
            type="primary"
            ghost
            onClick={() => setEditModalVisible(true)}
          >
            Edit
          </Button>,
          <Button
            key="clone"
            type="primary"
            ghost
            onClick={() => handleClone()}
          >
            Clone
          </Button>,

          <Button key="save" type="primary" onClick={() => saveCamino()}>
            Save
          </Button>,
          <Button key="add" type="primary" onClick={() => addMessage()}>
            Add message
          </Button>,
        ]}
      />

      {infoVisible && <EditorInfo />}

      <EditCaminoModal
        visible={editModalVisible}
        setVisible={setEditModalVisible}
      />

      <Chart />
    </Layout>
  );
};

export default CaminoEditorPage;
